import { useAppDispatch, useAppSelector } from "./hooks";
import { selectCount, decrement, increment, incrementByAmount } from "./Reducers/CounterSlice";
import { Button } from "react-bootstrap";

export function StartPage() {

    const dispatch = useAppDispatch();

    const nisse = useAppSelector(state => state.counter.value);



    return <>
        <p>{nisse}</p>

        <Button onClick={() => dispatch(increment())}>Increment</Button>
        <Button onClick={() => dispatch(decrement())}>Decrement</Button>


        <input type="text" onChange={(e) => dispatch(incrementByAmount(Number(e.target.value)))} />


    </>

}