import React from 'react';
import logo from './logo.svg';
import './App.css';
import { StartPage } from "./StartPage";
import { Alert } from "react-bootstrap";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
          <Alert key="success" variant="success">
              This is a success alert—check it out!
          </Alert>
          <StartPage />
      </header>

    </div>
  );
}

export default App;
